import { HeightWidth } from '@komo-tech/core/models/HeightWidth';

import { DataSourceInstance } from '../DataSource/DataSourceInstance';
import { ItemResolverFunc } from './BlockItem';
import { BlockItemData } from './BlockItemData';
import {
  BlockItemSizeAutoBehaviour,
  BlockRenderAutoBehaviour
} from './BlockRenderAutoBehaviour';
import { BlockRenderSize } from './BlockRenderSize';
import { BlockTypes } from './BlockTypes';

export class BlockItemSessionData<TData extends BlockItemData = BlockItemData> {
  parentId: string = '';
  canDelete: boolean = true;
  canClone: boolean = true;
  childOptions: BlockItemChildOptions;
  sizeOptions: BlockItemSizeOptions<TData>;
  dataSourceInstance?: DataSourceInstance;
  itemResolver: ItemResolverFunc;
  renderSize: BlockRenderSize;
  changeKeys = {
    data: 0,
    renderSize: 0
  };

  constructor(props?: Partial<BlockItemSessionData<TData>>) {
    props = props || {};
    Object.assign(this, props);
  }

  static new<TData extends BlockItemData = BlockItemData>(
    childOptions: BlockItemChildOptions,
    sizeOptions: BlockItemSizeOptions<TData>
  ) {
    return new BlockItemSessionData({
      sizeOptions,
      childOptions
    });
  }
}

export interface BlockItemChildOptions {
  enabled: boolean;
  allowedTypes?: BlockTypes[];
  notAllowedTypes?: BlockTypes[];
  defaultAutoBehaviour?: BlockItemSizeAutoBehaviour;
}

export interface BlockItemSizeOptions<
  TData extends BlockItemData = BlockItemData
> {
  aspectRatio: {
    enabled: boolean;
    locked?: {
      value: (data: TData) => HeightWidth | undefined;
      disabled: boolean;
    };
  };
  height: {
    lockedAutoBehaviour?: BlockRenderAutoBehaviour;
    fixedDisabled?: boolean;
  };
  width: {
    lockedAutoBehaviour?: BlockRenderAutoBehaviour;
    fixedDisabled?: boolean;
  };
}
