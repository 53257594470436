import { BlockDataSource } from '@/common/components/BlockBuilder/DataSource/BlockDataSource';
import { GetCardDataSourceDetails } from '@/common/components/BlockBuilder/DataSource/CardDataSource';
import { DataSourceDetails } from '@/common/components/BlockBuilder/DataSource/DataSourceDetails';
import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import { DataSourceTypes } from '@/common/components/BlockBuilder/DataSource/DataSourceTypes';
import { TextIcon } from '@/common/components/Icons/TextIcon';
import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { Quiz } from '@/common/models/quiz/Quiz';

interface Props {
  id: Guid;
  blockDataSource: BlockDataSource;
  isPortal?: boolean;
  isServerGenerated?: boolean;
}

export class BlockContactPropertyDataSourceInstance extends DataSourceInstance {
  isPortal?: boolean;
  isServerGenerated: boolean = false;

  constructor(props: Props) {
    super();
    Object.assign(this, props);
    this.applyDefaults();
  }

  isValid() {
    return this.hasSiteId;
  }

  isEqual(other: DataSourceInstance): boolean {
    return this.blockDataSource.isEqual(other.blockDataSource);
  }

  async getImageAssetsAsync(): Promise<ImageDataModel[]> {
    return [];
  }

  get siteId() {
    return this.blockDataSource.id1;
  }

  get hasSiteId() {
    return this.blockDataSource.hasId1;
  }

  getCardDataSourceDetails({}: GetCardDataSourceDetails<Quiz>): DataSourceDetails[] {
    const details: DataSourceDetails[] = [];
    if (!this.isValid()) {
      return details;
    }

    details.push({
      icon: (p) => <TextIcon {...p} />,
      header: 'Contact property',
      caption: 'name'
    });

    return details;
  }

  static isImplementedBy(
    object: DataSourceInstance
  ): object is BlockContactPropertyDataSourceInstance {
    return (
      !!object &&
      object.blockDataSource?.type === DataSourceTypes.KomoContactProperty
    );
  }
}
