import { ImagesPromiseFunc } from '@komo-tech/core/models/ImageDataModel';
import { CSSProperties } from 'react';

import {
  BlockItemData,
  BlockItemSizeAutoBehaviour,
  BlockMarginPadding,
  BlockTypes
} from '../../types/_shared';
import { BlockItem } from '../../types/BlockItem';

export class BlockButtonItem extends BlockItem<BlockButtonItemData> {
  type = BlockTypes.Button;
  constructor(props?: Partial<BlockButtonItem>) {
    props = props || {};
    super({
      childOptions: {
        enabled: true,
        allowedTypes: [BlockTypes.Text],
        defaultAutoBehaviour: {
          height: 'Hug',
          width: 'Hug'
        }
      },
      sizeOptions: {
        aspectRatio: {
          enabled: false
        },
        height: { lockedAutoBehaviour: 'Hug' },
        width: { lockedAutoBehaviour: 'Hug' }
      }
    });
    Object.assign(this, this.sanitiseProps(props));
    this.setDefaults();
  }

  getData(): BlockButtonItemData {
    return new BlockButtonItemData(this.safeParseJsonData());
  }

  resolveUpdateData(change: Partial<BlockButtonItemData>) {
    return new BlockButtonItemData({
      ...this.getData(),
      ...change
    });
  }

  resolveImageAssetsFuncAsync(): ImagesPromiseFunc {
    return () => Promise.resolve([]);
  }

  getRenderData(scale: number) {
    const data = this.getData();

    const style: CSSProperties = {
      ...this.getBaseStyle(scale),
      backgroundColor: data.backgroundColor,
      borderColor: data.borderColor,
      borderStyle: 'solid',
      borderWidth: data.borderWidth || 0,
      borderRadius: data.borderRadius,
      textDecoration: 'none'
    };

    let isLink = false;
    const viewAttributes: Record<string, string> = {};
    if (data.clickHandle?.variant === 'url') {
      isLink = true;
      viewAttributes['href'] = data.clickHandle.url;
      if (data.clickHandle.target) {
        viewAttributes['target'] = data.clickHandle.target;
      }
    }

    return {
      isLink,
      data,
      dataAttributes: this.getElementProps('Item').dataAttributes,
      viewAttributes,
      style
    };
  }

  static newFromUrl(
    url: string,
    data?: Partial<Omit<BlockButtonItemData, 'clickHandle'>> & {
      openInNewTab?: boolean;
    }
  ) {
    const { openInNewTab, ...rest } = data || {};
    return new BlockButtonItem({
      jsonData: JSON.stringify(
        BlockButtonItemData.fromUrl(
          {
            url,
            target: openInNewTab ? '_blank' : undefined
          },
          rest
        )
      )
    });
  }
}

export interface BlockButtonUrlClickHandleOptions {
  variant: 'url';
  url: string;
  target?: string;
}

export interface BlockButtonCustomClickHandleOptions {
  variant: 'custom';
}

export type BlockButtonClickHandler =
  | BlockButtonUrlClickHandleOptions
  | BlockButtonCustomClickHandleOptions;

export class BlockButtonItemData extends BlockItemData {
  backgroundColor: string;
  borderColor: string;
  borderRadius: number;
  borderWidth: number;
  clickHandle: BlockButtonClickHandler;
  constructor(props?: Partial<BlockButtonItemData>) {
    super();
    Object.assign(
      this,
      this.mergeDefaults({
        ...BlockButtonItemData.defaults,
        ...props
      })
    );
    this.setDefaults();
  }

  defaultAutoBehaviour(): BlockItemSizeAutoBehaviour {
    return {
      height: 'Hug',
      width: 'Hug'
    };
  }

  static defaults: Partial<BlockButtonItemData> = {
    backgroundColor: '#000000',
    borderColor: '#000000',
    color: '#FFFFFF',
    childStack: 'Horizontal',
    borderRadius: 0,
    borderWidth: 1,
    fontSize: 18,
    padding: BlockMarginPadding.fromValues(12, 24),
    hAlign: 'Center',
    vAlign: 'Center'
  };

  static fromUrl(
    data: Omit<BlockButtonUrlClickHandleOptions, 'variant'>,
    props?: Partial<Omit<BlockButtonItemData, 'clickHandle'>>
  ) {
    return new BlockButtonItemData({
      ...props,
      clickHandle: {
        variant: 'url',
        ...data
      }
    });
  }
}
