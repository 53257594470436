'use client';
import { FC, useEffect } from 'react';

import { useId } from '@komo-tech/core/hooks/useId';
import { PreloadFontInfo } from '@/common/models/PreloadFontInfo';

interface Props {
  customFontCss: string;
  preloads?: PreloadFontInfo[];
}
export const CustomFontsHead: FC<Props> = ({
  customFontCss,
  preloads = []
}) => {
  const customFontCssId = useId({
    prefix: 'custom-fonts-'
  });

  useEffect(() => {
    if (customFontCss) {
      updateCustomFontCss(customFontCssId, customFontCss);
    }
  }, [customFontCss]);

  useEffect(() => {
    if (preloads?.length) {
      preloads.forEach(updatePreloadFont);
    }
  }, [preloads]);

  return null;
};

const updatePreloadFont = (value: PreloadFontInfo) => {
  try {
    const id = `link-${value.id || value.sourceUrl}`;
    const existing = document.getElementById(id) as HTMLLinkElement;
    if (existing) {
      setLinkData(existing, value);
      return;
    }

    const link = document.createElement('link');
    setLinkData(link, value);
    document.head.appendChild(link);
  } catch (e) {
    console.error(e, 'Error updating preload font');
  }
};

const setLinkData = (link: HTMLLinkElement, value: PreloadFontInfo) => {
  link.rel = 'preload';
  link.as = 'font';
  link.type = value.mimeType;
  link.href = value.sourceUrl;
  link.crossOrigin = 'anonymous';
};

const updateCustomFontCss = (id: string, value: string) => {
  try {
    const existing = document.getElementById(id);
    if (existing) {
      existing.innerHTML = value;
      return;
    }

    const style = document.createElement('style');
    style.id = id;
    style.innerHTML = value;
    document.head.appendChild(style);
  } catch (e) {
    console.error(e, 'Error updating custom font css');
  }
};
