import cx from 'clsx';
import { FC, ReactNode } from 'react';

import {
  CardShell,
  CardShellProps
} from '@/common/components/Card/shared/CardShell';
import { Box, BoxProps } from '@/common/components/Display/Box';
import { Divider } from '@/common/components/Display/Divider';
import { Group } from '@/common/components/Display/Group';
import { Stack } from '@/common/components/Display/Stack';
import { SkeletonV2, SkeletonV2Props } from '@/common/components/Skeleton';
import { CardTypes } from '@/common/models/CardTypes';
import { HeightWidth } from '@komo-tech/core/models/HeightWidth';
import { aspectRatioCss } from '@komo-tech/core/utils/size';

import classes from './CardSkeleton.module.css';

interface FooterPlaceholderProps
  extends Pick<BoxProps, 'p' | 'px' | 'py' | 'pt' | 'pb'> {
  withBorderTop?: boolean;
}
export const FooterPlaceholder: FC<FooterPlaceholderProps> = ({
  p = '0.75rem',
  withBorderTop = true,
  ...rest
}) => {
  return (
    <Box
      p={p}
      {...rest}
      data-with-border={withBorderTop}
      className={classes.footer}
    >
      <SkeletonComponent w={64} h={30} />
    </Box>
  );
};

interface InnerContentProps {
  imageAspectRatio?: HeightWidth;
}

interface CardContentSkeletonProps extends InnerContentProps {
  type: CardTypes;
  children?: ReactNode;
}

const ChecklistSkeletonContent: FC<InnerContentProps> = () => {
  return (
    <>
      <Box p="0.5rem">
        <Group
          justify="space-between"
          w={'100%'}
          mb={4}
          gap={0}
          style={{ boxPack: 'justify' }}
        >
          <Stack w="100%" gap={0}>
            <SkeletonComponent h={17} mb={7} w={'60%'} />
            <SkeletonComponent mb={15} w={'80%'} h={17} />
          </Stack>
          <SkeletonComponent mb={15} h={50} w={90} />
        </Group>
        <SkeletonComponent mb={15} w={'100%'} h={34} />
        <SkeletonComponent mb={15} w={'100%'} h={34} />
      </Box>
      <Divider />
    </>
  );
};

const NumberGuessSkeletonContent: FC<InnerContentProps> = ({
  imageAspectRatio
}) => (
  <>
    {imageAspectRatio ? (
      <SkeletonComponent
        pos={'relative'}
        w={'100%'}
        bgr={'unset'}
        style={{ aspectRatio: aspectRatioCss(imageAspectRatio) }}
      />
    ) : (
      <SkeletonComponent w={'100%'} h={250} />
    )}
    <Group
      align={'center'}
      pos="relative"
      h={85}
      maw={580}
      m={'0.5rem 0 0.5rem 0.75rem'}
    >
      <SkeletonComponent w={80} h={80} mb={'0.5rem'} pos={'absolute'} />
      <SkeletonComponent mb={15} w={'100%'} h={34 / 3} />
    </Group>
  </>
);

const ImageSkeletonContent: FC<InnerContentProps> = ({ imageAspectRatio }) => (
  <>
    {imageAspectRatio ? (
      <SkeletonComponent
        pos={'relative'}
        w={'100%'}
        bgr={'unset'}
        style={{ aspectRatio: aspectRatioCss(imageAspectRatio) }}
      />
    ) : (
      <SkeletonComponent w={'100%'} h={250} />
    )}
  </>
);

export const CardsListSkeleton: FC = () => (
  <>
    <CardSkeleton type={CardTypes.Quiz} />
    <CardSkeleton type={CardTypes.Image} />
    <CardSkeleton type={CardTypes.Quiz} />
    <CardSkeleton type={CardTypes.Quiz} />
    <CardSkeleton type={CardTypes.Image} />
  </>
);

interface CardSkeletonProps extends CardShellProps {
  imageAspectRatio?: HeightWidth;
}

export const CardSkeleton: FC<CardSkeletonProps & { type: CardTypes }> = ({
  type,
  imageAspectRatio,
  ...rest
}) => {
  return (
    <CardShell {...rest}>
      <CardContentSkeleton type={type} imageAspectRatio={imageAspectRatio}>
        <FooterPlaceholder withBorderTop={false} p={0} pt="0.75rem" />
      </CardContentSkeleton>
    </CardShell>
  );
};

export const CardContentSkeleton: FC<CardContentSkeletonProps> = ({
  type,
  imageAspectRatio,
  children
}) => {
  const getView = () => {
    switch (type) {
      case CardTypes.NumberGuess:
        return (
          <NumberGuessSkeletonContent imageAspectRatio={imageAspectRatio} />
        );
      case CardTypes.Checklist:
        return <ChecklistSkeletonContent imageAspectRatio={imageAspectRatio} />;
      default:
        return <ImageSkeletonContent imageAspectRatio={imageAspectRatio} />;
    }
  };

  return (
    <Box p={'0.5rem 0.5rem 1rem'}>
      {getView()}
      {children}
    </Box>
  );
};

const SkeletonComponent = ({ className, ...rest }: SkeletonV2Props) => {
  return <SkeletonV2 className={cx(classes.root, className)} {...rest} />;
};
