import { BlockDataSource } from '@/common/components/BlockBuilder/DataSource/BlockDataSource';
import { DataSourceDetails } from '@/common/components/BlockBuilder/DataSource/DataSourceDetails';
import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import { DataSourceTypes } from '@/common/components/BlockBuilder/DataSource/DataSourceTypes';
import { AwardIcon } from '@/common/components/Icons/AwardIcon';
import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';

interface Props {
  id: Guid;
  blockDataSource: BlockDataSource;
  isPortal?: boolean;
  isServerGenerated?: boolean;
}

interface DataRequest {
  id: string;
  take: number;
}

interface PreloadDataRequest extends DataRequest {}

export class BlockContactBadgesDataSourceInstance extends DataSourceInstance {
  token?: string;
  isPortal: boolean;
  isServerGenerated: boolean = false;

  constructor(props?: Partial<Props>) {
    props = props || {};
    super();
    Object.assign(this, props);
    this.applyDefaults();
  }

  isValid(): boolean {
    return this.hasSiteId;
  }

  isEqual(other: DataSourceInstance): boolean {
    return this.blockDataSource.isEqual(other.blockDataSource);
  }

  getImageAssetsAsync(): Promise<ImageDataModel[]> {
    return Promise.resolve([]);
  }

  async tryPreloadItemsAsync(
    request: PreloadDataRequest
  ): Promise<{ images: ImageDataModel[] }> {
    // const page = await this.getNextPageAsync(request);

    // if (page?.items) {
    //   this.pushCache(request.id, { page });
    // }

    // const images: ImageDataModel[] = [];
    // if (page?.items?.length) {
    //   page.items
    //     .filter((x) => !!x.image)
    //     .forEach((x) => {
    //       const image = x.image;
    //       //I'm not cropping images
    //       // image.tryApplyCropCoordinates(request.imageCropData);
    //       images.push(image);
    //     });
    // }

    // return { images };
    return { images: [] };
  }

  get siteId() {
    return this.blockDataSource.id1;
  }

  get hasSiteId() {
    return this.blockDataSource.hasId1;
  }

  getDataAsync() {}

  getDataSourceDetails(): DataSourceDetails[] {
    return [
      {
        icon: (p) => <AwardIcon {...p} />,
        header: 'Contact badges',
        caption: 'cool'
      }
    ];
  }

  get expectedDetailItems() {
    return 1;
  }

  static isImplementedBy(
    object: DataSourceInstance
  ): object is BlockContactBadgesDataSourceInstance {
    return (
      !!object &&
      object.blockDataSource?.type === DataSourceTypes.KomoContactBadges
    );
  }
}
